import React from 'react';
import { Helmet } from 'react-helmet';
import { compose } from 'recompose';

import * as CONFIG from '../../SiteConfig';

import Layout from '../components/templates/Default';
import {
  AuthUserContext,
  withAuthorization,
  withEmailVerification,
} from '../components/particles/Session';
import PasswordForgetForm from '../components/particles/PasswordForget';
import PasswordChangeForm from '../components/particles/PasswordChange';

const AccountPageBase = () => (
  <>
    <AuthUserContext.Consumer>
      {authUser => (
        <section className="grid">
          <h1 class="h4">Account: </h1>
          <span class="h6">{authUser.email}</span>
          <PasswordForgetForm />
          <PasswordChangeForm />
        </section>
      )}
    </AuthUserContext.Consumer>
  </>
);

const condition = authUser => !!authUser;

const AccountPage = compose(
  withEmailVerification,
  withAuthorization(condition),
)(AccountPageBase);

export default () => (
  <Layout>
    <Helmet
      title={`${CONFIG.SITE_TITLE} | Account Page`}
      defer={false}
    />
    <AccountPage />
  </Layout>
);
